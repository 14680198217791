#root {
  min-height: 100vh;
}
body {
  min-height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  max-width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'IBMPlexSans-Bold';
  src:
    local('IBMPlexSans-Bold'),
    url('./fonts/IBMPlexSans/IBMPlexSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans-BoldItalic';
  src:
    local('IBMPlexSans-BoldItalic'),
    url('./fonts/IBMPlexSans/IBMPlexSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans-ExtraLight';
  src:
    local('IBMPlexSans-ExtraLight'),
    url('./fonts/IBMPlexSans/IBMPlexSans-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans-ExtraLightItalic';
  src:
    local('IBMPlexSans-ExtraLightItalic'),
    url('./fonts/IBMPlexSans/IBMPlexSans-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans-Italic';
  src:
    local('IBMPlexSans-Italic'),
    url('./fonts/IBMPlexSans/IBMPlexSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans-Light';
  src:
    local('IBMPlexSans-Light'),
    url('./fonts/IBMPlexSans/IBMPlexSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans-LightItalic';
  src:
    local('IBMPlexSans-LightItalic'),
    url('./fonts/IBMPlexSans/IBMPlexSans-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans-Medium';
  src:
    local('IBMPlexSans-Medium'),
    url('./fonts/IBMPlexSans/IBMPlexSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans-MediumItalic';
  src:
    local('IBMPlexSans-MediumItalic'),
    url('./fonts/IBMPlexSans/IBMPlexSans-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans-Regular';
  src:
    local('IBMPlexSans-Regular'),
    url('./fonts/IBMPlexSans/IBMPlexSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans-SemiBold';
  src:
    local('IBMPlexSans-SemiBold'),
    url('./fonts/IBMPlexSans/IBMPlexSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans-SemiBoldItalic';
  src:
    local('IBMPlexSans-SemiBoldItalic'),
    url('./fonts/IBMPlexSans/IBMPlexSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans-Thin';
  src:
    local('IBMPlexSans-Thin'),
    url('./fonts/IBMPlexSans/IBMPlexSans-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans-ThinItalic';
  src:
    local('IBMPlexSans-ThinItalic'),
    url('./fonts/IBMPlexSans/IBMPlexSans-ThinItalic.ttf') format('truetype');
}

@media (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 14px;
    border-radius: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #848484;
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
